@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap');

table {
    border-collapse: collapse;
    background-color: white;
    overflow: hidden;
    width: 100%;
}

th,
td {
    font-family: 'Motnserrat', sans-serif;
    text-align: left;
    font-size: 12px;
    padding: 10px !important;
}

th {
    background-color: #7691ab;
    color: white;
}