@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap');

.textimonial table {
    margin-top: 25px;
    border-collapse: collapse;
    background-color: white;
    overflow: hidden;
    width: 100%;
}

.textimonial th,
.textimonial td {
    font-family: 'Motnserrat', sans-serif;
    text-align: left;
    font-size: 12px;
    padding: 10px !important;
}

.textimonial th {
    background-color: #7691ab;
    color: white;
}