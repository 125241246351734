@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap);
.page-container {
    margin-bottom: 30px;
}

.login {
	background: #f6f5f7;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	height: 100vh;
}

.login h1 {
	font-weight: bold;
	margin: 0;
}

.login h2 {
	text-align: center;
}

.login p {
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
}

.login span {
	font-size: 12px;
}

.login a {
	color: #333;
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
}

.login .error-message {
    color: red;
}

.login button {
	border-radius: 20px;
	border: 1px solid #ffab72;
	background-color: #ffab72;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	-webkit-transition: -webkit-transform 80ms ease-in;
	transition: -webkit-transform 80ms ease-in;
	transition: transform 80ms ease-in;
	transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
}

.login button:active {
	-webkit-transform: scale(0.95);
	        transform: scale(0.95);
}

.login button:focus {
	outline: none;
}

.login button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}

.login form {
	background-color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;
}

.login input {
	background-color: #eee;
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
}

.login .container {
	background-color: #fff;
	border-radius: 10px;
  	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
			0 10px 10px rgba(0,0,0,0.22);
	position: relative;
	overflow: hidden;
	width: 768px;
	max-width: 100%;
	min-height: 480px;
}

.login .form-container {
	position: absolute;
	top: 0;
	height: 100%;
	-webkit-transition: all 0.6s ease-in-out;
	transition: all 0.6s ease-in-out;
}

.login .sign-in-container {
	left: 0;
	width: 50%;
	z-index: 2;
}

.login .container.right-panel-active .sign-in-container {
	-webkit-transform: translateX(100%);
	        transform: translateX(100%);
}

.login .sign-up-container {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.login .container.right-panel-active .sign-up-container {
	-webkit-transform: translateX(100%);
	        transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	-webkit-animation: show 0.6s;
	        animation: show 0.6s;
}

@-webkit-keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}

@keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}

.login .overlay-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	-webkit-transition: -webkit-transform 0.6s ease-in-out;
	transition: -webkit-transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
	z-index: 100;
}

.login .container.right-panel-active .overlay-container{
	-webkit-transform: translateX(-100%);
	        transform: translateX(-100%);
}

.login .overlay {
	background: #ffab72;
	background: -webkit-gradient(linear, left top, right top, from(#ffab72), to(#ffab72));
	background: linear-gradient(to right, #ffab72, #ffab72);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #FFFFFF;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
  	-webkit-transform: translateX(0);
  	        transform: translateX(0);
	-webkit-transition: -webkit-transform 0.6s ease-in-out;
	transition: -webkit-transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

.login .container.right-panel-active .overlay {
  	-webkit-transform: translateX(50%);
  	        transform: translateX(50%);
}

.login .overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	-webkit-transform: translateX(0);
	        transform: translateX(0);
	-webkit-transition: -webkit-transform 0.6s ease-in-out;
	transition: -webkit-transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

.login .overlay-left {
	-webkit-transform: translateX(-20%);
	        transform: translateX(-20%);
}

.login .container.right-panel-active .overlay-left {
	-webkit-transform: translateX(0);
	        transform: translateX(0);
}

.login .overlay-right {
	right: 0;
	-webkit-transform: translateX(0);
	        transform: translateX(0);
}

.login .container.right-panel-active .overlay-right {
	-webkit-transform: translateX(20%);
	        transform: translateX(20%);
}

.login .social-container {
	margin: 20px 0;
}
.dashboard {
    margin-bottom: 10vh;
}

.dashboard .order-card {
    color: #fff;
}

.dashboard .bg-c-blue {
    background: linear-gradient(45deg, #4099ff, #73b4ff);
}

.dashboard .bg-c-green {
    background: linear-gradient(45deg, #2ed8b6, #59e0c5);
}

.dashboard .bg-c-yellow {
    background: linear-gradient(45deg, #FFB64D, #ffcb80);
}

.dashboard .bg-c-pink {
    background: linear-gradient(45deg, #FF5370, #ff869a);
}


.dashboard .card {
    border-radius: 5px;
    box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
    border: none;
    margin-bottom: 30px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.dashboard .card .card-block {
    padding: 25px;
}

.dashboard .order-card i {
    font-size: 26px;
}

.dashboard .f-left {
    float: left;
}

.dashboard .f-right {
    float: right;
}
.admin-header {
    font-size: 12px;
    font-family: 'Josefin Sans', 'Roboto', sans-serif;
    background-color: #003e7c;
    color: rgba(255, 255, 255, 0.75);
    overflow: hidden;
}

.admin-header a {
    color: inherit;
    text-decoration: none;
    outline: none;
}

.admin-header li {
    width: 100%;
}

.admin-header svg {
    width: 100%;
    max-width: 30px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.admin-header .u-uppercase {
    margin-top: 10px;
    text-transform: uppercase;
    font-size: smaller;
}

.admin-header .fake-button::active {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
}

.admin-header .flex-column-nowrap {
    display: flex;
    flex-flow: column nowrap;
}

.admin-header .flex-row-wrap {
    display: flex;
    flex-flow: row wrap;
    margin-top: 20px;
}

.admin-header .flex-row-nowrap {
    display: flex;
    flex-flow: row nowrap;
}

.admin-header .sidebar__logo,
.navbar__logo {
    position: relative;
    margin-top: .5rem;
    padding-top: .4rem;
    z-index: 10;
}

.admin-header .sidebar__logo .logo,
.navbar__logo .logo {
    top: 50%;
    left: 50%;
    color: rgba(255, 255, 255, 0.75);
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: -4px;
    text-shadow: 0 0.2rem 0.4rem #2f2f32, 0 0.2rem 0.4rem #2f2f32;
}

.admin-header .sidebar__logo1::before,
.sidebar__logo1::after,
.admin-header .navbar__logo1::before,
.admin-header .navbar__logo1::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
    width: 2rem;
    padding: 1rem;
    border: 5px solid #ffab72;
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
    -webkit-animation: 10s infinite alternate ease-in-out spin;
    animation: 10s infinite alternate ease-in-out spin;
}

.admin-header .sidebar__logo::before,
.navbar__logo::before {
    border-color: #ffab72 #ffab72 transparent transparent;
    z-index: -1;
}

.admin-header .sidebar__logo::after,
.navbar__logo::after {
    border-color: transparent transparent #ffab72 #ffab72;
    z-index: 1;
    box-shadow: 4px 2px 1px #2f2f32;
}

@media screen and (max-width: 768px) {

    .sidebar__logo,
    .navbar__logo {
        margin: 0 0 0 1.5rem;
    }
}

@media screen and (max-width: 550px) {

    .sidebar__logo,
    .navbar__logo {
        margin: 0 0 0 2.5rem;
    }
}

.admin-header .desktop-menu {
    display: flex;
    position: relative;
}

.admin-header .desktop-menu .sidebar {
    position: fixed;
    width: 100%;
    max-width: 75px;
    height: 100vh;
    justify-content: space-between;
    align-content: center;
    text-align: center;
    z-index: 10;
    background-color: #142B43;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
}

@media screen and (max-width: 768px) {
    .desktop-menu .sidebar {
        width: 100%;
        max-width: 100vw;
        height: 100%;
        max-height: 75px;
        flex-flow: row nowrap;
        align-items: center;
    }
}

.admin-header .desktop-menu .sidebar__nav-list {
    padding: 0;
}

.admin-header .desktop-menu .sidebar__nav-list .nav-list__item {
    opacity: .5;
    cursor: pointer;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.admin-header .desktop-menu .sidebar__nav-list .nav-list__item a {
    position: relative;
    justify-content: center;
    align-items: center;
    -webkit-transition: -webkit-transform .15s ease;
    transition: -webkit-transform .15s ease;
    transition: transform .15s ease;
    transition: transform .15s ease, -webkit-transform .15s ease;
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__nav-list .nav-list__item a {
        height: 100%;
    }
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__nav-list .nav-list__item a svg {
        align-self: flex-end;
        margin-top: .5rem;
    }
}

.admin-header .desktop-menu .sidebar__nav-list .nav-list__item a span {
    display: block;
    flex-basis: 100%;
    padding-top: .25rem;
    -webkit-transform: translateX(-200px);
    transform: translateX(-200px);
    -webkit-transition: all .15s ease;
    transition: all .15s ease;
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__nav-list .nav-list__item a span {
        -webkit-transform: translateY(-200px);
        transform: translateY(-200px);
        align-self: flex-end;
        padding-bottom: .5rem;
    }
}

.admin-header .desktop-menu .sidebar__nav-list .nav-list__item:last-child {
    margin-bottom: 0;
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__nav-list .nav-list__item:last-child {
        margin-right: 0;
    }
}

.admin-header .desktop-menu .sidebar__nav-list .nav-list__item.active,
.admin-header .desktop-menu .sidebar__nav-list .nav-list__item:hover {
    opacity: 1;
}

.admin-header .desktop-menu .sidebar__nav-list .nav-list__item.active span,
.admin-header .desktop-menu .sidebar__nav-list .nav-list__item:hover span {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    color: #ffab72;
    font-weight: bold;
}

.admin-header .desktop-menu .sidebar__nav-list .nav-list__item.active svg,
.admin-header .desktop-menu .sidebar__nav-list .nav-list__item:hover svg {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    -webkit-animation: dash 12s ease alternate;
    animation: dash 12s ease alternate;
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__nav-list .nav-list__item {
        height: 100%;
        margin: 0;
    }
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__nav-list {
        flex-flow: row nowrap;
        align-items: center;
        width: 100%;
        max-width: 45%;
        height: 100%;
    }
}

@media screen and (max-width: 550px) {
    .admin-header .desktop-menu .sidebar__nav-list {
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .admin-header .desktop-menu {
        display: none;
    }
}

.admin-header .desktop-menu .sidebar__extra-content {
    display: flex;
    flex-flow: column nowrap;
    padding: 0;
}

.admin-header .desktop-menu .sidebar__extra-content span {
    display: block;
    width: 100%;
    text-align: center;
    color: #ffab72;
    font-weight: bold;
    -webkit-transition: all .15s ease;
    transition: all .15s ease;
    -webkit-transform: translateX(-200px);
    transform: translateX(-200px);
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__extra-content span {
        align-self: flex-end;
        padding-bottom: .5rem;
        -webkit-transform: translateY(-200px);
        transform: translateY(-200px);
    }
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language {
    display: flex;
    position: relative;
    flex-flow: row wrap;
    justify-content: center;
    margin-bottom: 1rem;
    cursor: pointer;
    -webkit-transition: all .15s ease;
    transition: all .15s ease;
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__extra-content .extra-content__language svg {
        align-self: flex-end;
        margin-top: .5rem;
    }
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector {
    position: absolute;
    top: 0;
    right: -200px;
    left: 75px;
    width: 130px;
    padding-left: 1.5rem;
    visibility: hidden;
    z-index: 1;
    -webkit-transition: visibility 0ms ease-out 600ms;
    transition: visibility 0ms ease-out 600ms;
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector .language-selector__item {
    margin-bottom: 0;
    padding: .5rem 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.75);
    background-color: #1b1d1d;
    opacity: 0;
    -webkit-transform: translateX(-200px);
    transform: translateX(-200px);
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector .language-selector__item a {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector .language-selector__item span {
    width: 25px;
    height: 6px;
    align-self: center;
    color: rgba(255, 255, 255, 0.75);
    -webkit-transform: scale(1);
    transform: scale(1);
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector .language-selector__item svg {
        margin: 0;
    }
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector .language-selector__item:nth-of-type(1) {
    border-top: 1px solid rgba(255, 255, 255, 0.75);
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector .language-selector__item:nth-child(1) {
    -webkit-transition: opacity 100ms ease-out 0.3s, -webkit-transform 0.15s ease 0.3s;
    transition: opacity 100ms ease-out 0.3s, -webkit-transform 0.15s ease 0.3s;
    transition: transform 0.15s ease 0.3s, opacity 100ms ease-out 0.3s;
    transition: transform 0.15s ease 0.3s, opacity 100ms ease-out 0.3s, -webkit-transform 0.15s ease 0.3s;
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector .language-selector__item:nth-child(2) {
    -webkit-transition: opacity 100ms ease-out 0.2s, -webkit-transform 0.15s ease 0.2s;
    transition: opacity 100ms ease-out 0.2s, -webkit-transform 0.15s ease 0.2s;
    transition: transform 0.15s ease 0.2s, opacity 100ms ease-out 0.2s;
    transition: transform 0.15s ease 0.2s, opacity 100ms ease-out 0.2s, -webkit-transform 0.15s ease 0.2s;
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector .language-selector__item:nth-child(3) {
    -webkit-transition: opacity 100ms ease-out 0.1s, -webkit-transform 0.15s ease 0.1s;
    transition: opacity 100ms ease-out 0.1s, -webkit-transform 0.15s ease 0.1s;
    transition: transform 0.15s ease 0.1s, opacity 100ms ease-out 0.1s;
    transition: transform 0.15s ease 0.1s, opacity 100ms ease-out 0.1s, -webkit-transform 0.15s ease 0.1s;
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector .language-selector__item.is-selected {
    background-color: #585f5f;
    font-weight: bold;
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector .language-selector__item.is-selected a span {
    color: #ffab72;
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector .language-selector__item:hover {
    background-color: #585f5f;
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector .language-selector__item {
        -webkit-transform: translateX(200px);
        transform: translateX(200px);
    }
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector {
        left: -17px;
        top: 83px;
    }
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language svg:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 2rem;
    width: 1.1rem;
    opacity: 0;
    -webkit-transform: translateY(-85%);
    transform: translateY(-85%);
    -webkit-transition: all .15s ease;
    transition: all .15s ease;
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__extra-content .extra-content__language svg:nth-of-type(2) {
        -webkit-transform: translateY(-130%) rotate(90deg);
        transform: translateY(-130%) rotate(90deg);
    }
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language:hover {
    -webkit-transform: translateX(-1rem);
    transform: translateX(-1rem);
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language:hover span {
    -webkit-transform: translateX(1rem);
    transform: translateX(1rem);
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language:hover svg:nth-of-type(2) {
    opacity: 1;
    right: -.5rem;
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language.is-open {
    -webkit-transform: translateX(-1rem);
    transform: translateX(-1rem);
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language.is-open .extra-content__language-selector {
    visibility: visible;
    -webkit-transition: visibility 0ms ease-out 0ms;
    transition: visibility 0ms ease-out 0ms;
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language.is-open .extra-content__language-selector li:nth-of-type(1) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    -webkit-transition: opacity 0.1s ease-out 0.1s, -webkit-transform 0.15s ease 0.1s;
    transition: opacity 0.1s ease-out 0.1s, -webkit-transform 0.15s ease 0.1s;
    transition: transform 0.15s ease 0.1s, opacity 0.1s ease-out 0.1s;
    transition: transform 0.15s ease 0.1s, opacity 0.1s ease-out 0.1s, -webkit-transform 0.15s ease 0.1s;
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language.is-open .extra-content__language-selector li:nth-of-type(2) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    -webkit-transition: opacity 0.1s ease-out 0.2s, -webkit-transform 0.15s ease 0.2s;
    transition: opacity 0.1s ease-out 0.2s, -webkit-transform 0.15s ease 0.2s;
    transition: transform 0.15s ease 0.2s, opacity 0.1s ease-out 0.2s;
    transition: transform 0.15s ease 0.2s, opacity 0.1s ease-out 0.2s, -webkit-transform 0.15s ease 0.2s;
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language.is-open .extra-content__language-selector li:nth-of-type(3) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    -webkit-transition: opacity 0.1s ease-out 0.3s, -webkit-transform 0.15s ease 0.3s;
    transition: opacity 0.1s ease-out 0.3s, -webkit-transform 0.15s ease 0.3s;
    transition: transform 0.15s ease 0.3s, opacity 0.1s ease-out 0.3s;
    transition: transform 0.15s ease 0.3s, opacity 0.1s ease-out 0.3s, -webkit-transform 0.15s ease 0.3s;
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language.is-open span {
    -webkit-transform: scale(0.95) translateX(1rem);
    transform: scale(0.95) translateX(1rem);
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language.is-open svg {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language.is-open svg:nth-of-type(2) {
    right: -.5rem;
    opacity: 1;
    -webkit-transform: translateY(-85%) scale(0.9) rotate(180deg);
    transform: translateY(-85%) scale(0.9) rotate(180deg);
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__extra-content .extra-content__language.is-open svg:nth-of-type(2) {
        -webkit-transform: translateY(-130%) scale(0.9) rotate(-90deg);
        transform: translateY(-130%) scale(0.9) rotate(-90deg);
    }
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__extra-content .extra-content__language {
        margin-bottom: 0;
    }
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__share {
    justify-content: center;
    margin-bottom: .5rem;
    cursor: pointer;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__extra-content .extra-content__share svg {
        align-self: flex-end;
        margin-top: .5rem;
    }
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__extra-content .extra-content__share span {
        align-self: flex-end;
        padding-bottom: .5rem;
    }
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__share:hover span {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__extra-content .extra-content__share {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__extra-content {
        flex-flow: row nowrap;
        height: 100%;
    }
}

@media screen and (max-width: 550px) {
    .admin-header .desktop-menu .sidebar__extra-content {
        display: none;
    }
}

.admin-header .mobile-menu {
    display: none;
    position: relative;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
    background-color: #1b1d1d;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.admin-header .mobile-menu .navbar__mobile-menu {
    display: none;
    position: relative;
    height: 100%;
}

.admin-header .mobile-menu .navbar__mobile-menu .menu {
    position: relative;
    width: 50px;
    height: 40px;
    margin: 1.55rem 1.55rem 0 1.55rem;
    text-align: left;
    cursor: pointer;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
}

.admin-header .mobile-menu .navbar__mobile-menu .menu span {
    display: inline-block;
    position: absolute;
    height: 3px;
    width: 50px;
    background: rgba(255, 255, 255, 0.75);
    border-radius: 2px;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
}

.admin-header .mobile-menu .navbar__mobile-menu .menu span:nth-of-type(1) {
    -webkit-transition: width .15s .15s, top .15s, right .15s, -webkit-transform .15s;
    transition: width .15s .15s, top .15s, right .15s, -webkit-transform .15s;
    transition: width .15s .15s, top .15s, right .15s, transform .15s;
    transition: width .15s .15s, top .15s, right .15s, transform .15s, -webkit-transform .15s;
}

.admin-header .mobile-menu .navbar__mobile-menu .menu span:nth-of-type(2) {
    top: 10px;
    -webkit-transition: -webkit-transform .15s;
    transition: -webkit-transform .15s;
    transition: transform .15s;
    transition: transform .15s, -webkit-transform .15s;
}

.admin-header .mobile-menu .navbar__mobile-menu .menu span:nth-of-type(3) {
    top: 20px;
    right: 0;
    -webkit-transition: width .15s .15s, top .15s, right .15s, -webkit-transform .15s;
    transition: width .15s .15s, top .15s, right .15s, -webkit-transform .15s;
    transition: width .15s .15s, top .15s, right .15s, transform .15s;
    transition: width .15s .15s, top .15s, right .15s, transform .15s, -webkit-transform .15s;
}

@media screen and (max-width: 550px) {
    .admin-header .mobile-menu .navbar__mobile-menu {
        display: block;
    }
}

.admin-header .mobile-menu .navbar__mobile-menu__nav {
    display: block;
    position: absolute;
    left: 100%;
    top: 64px;
    height: calc(100vh - 65px);
    min-height: calc(100vh - 65px);
    width: 100vw;
    background: #1b1d1d;
    overflow-x: hidden;
    -webkit-transition: all 0.3s ease 0.5s;
    transition: all 0.3s ease 0.5s;
}

.admin-header .mobile-menu .navbar__mobile-menu__nav .navbar__mobile-menu__list {
    margin-top: 1rem;
    font-size: 1.5rem;
    justify-content: center;
}

.admin-header .mobile-menu .navbar__mobile-menu__nav .navbar__mobile-menu__list .navbar__mobile-menu__item {
    padding: 1rem 0;
    justify-content: center;
    cursor: pointer;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    width: 75%;
    margin: 0 auto;
    border-bottom: 1px solid rgba(255, 255, 255, 0.75);
    -webkit-transform: translateX(550px);
    transform: translateX(550px);
}

.admin-header .mobile-menu .navbar__mobile-menu__nav .navbar__mobile-menu__list .navbar__mobile-menu__item:nth-child(1) {
    -webkit-transition: -webkit-transform 0.15s ease 0.3s;
    transition: -webkit-transform 0.15s ease 0.3s;
    transition: transform 0.15s ease 0.3s;
    transition: transform 0.15s ease 0.3s, -webkit-transform 0.15s ease 0.3s;
}

.admin-header .mobile-menu .navbar__mobile-menu__nav .navbar__mobile-menu__list .navbar__mobile-menu__item:nth-child(2) {
    -webkit-transition: -webkit-transform 0.15s ease 0.2s;
    transition: -webkit-transform 0.15s ease 0.2s;
    transition: transform 0.15s ease 0.2s;
    transition: transform 0.15s ease 0.2s, -webkit-transform 0.15s ease 0.2s;
}

.admin-header .mobile-menu .navbar__mobile-menu__nav .navbar__mobile-menu__list .navbar__mobile-menu__item:nth-child(3) {
    -webkit-transition: -webkit-transform 0.15s ease 0.1s;
    transition: -webkit-transform 0.15s ease 0.1s;
    transition: transform 0.15s ease 0.1s;
    transition: transform 0.15s ease 0.1s, -webkit-transform 0.15s ease 0.1s;
}

.admin-header .mobile-menu .navbar__mobile-menu__nav .navbar__mobile-menu__list .navbar__mobile-menu__item:nth-child(4) {
    -webkit-transition: -webkit-transform 0.15s ease 0s;
    transition: -webkit-transform 0.15s ease 0s;
    transition: transform 0.15s ease 0s;
    transition: transform 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
}

.admin-header .mobile-menu .navbar__mobile-menu__nav .navbar__mobile-menu__list .navbar__mobile-menu__item:nth-child(5) {
    -webkit-transition: -webkit-transform 0.15s ease -0.1s;
    transition: -webkit-transform 0.15s ease -0.1s;
    transition: transform 0.15s ease -0.1s;
    transition: transform 0.15s ease -0.1s, -webkit-transform 0.15s ease -0.1s;
}

.admin-header .mobile-menu .navbar__mobile-menu__nav .navbar__mobile-menu__list .navbar__mobile-menu__item:nth-child(6) {
    -webkit-transition: -webkit-transform 0.15s ease -0.2s;
    transition: -webkit-transform 0.15s ease -0.2s;
    transition: transform 0.15s ease -0.2s;
    transition: transform 0.15s ease -0.2s, -webkit-transform 0.15s ease -0.2s;
}

.admin-header .mobile-menu .navbar__mobile-menu__nav .navbar__mobile-menu__list .navbar__mobile-menu__item.active {
    color: #ffab72;
    border-bottom-color: #ffab72;
}

.admin-header .mobile-menu .navbar__mobile-menu__nav .navbar__mobile-menu__list .navbar__mobile-menu__item:active a {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
}

.admin-header .mobile-menu.is-open {
    box-shadow: unset;
}

.admin-header .mobile-menu.is-open .menu span:nth-of-type(1) {
    top: 2%;
    left: 7%;
    width: 50%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: width .15s, top .15s .15s, left .15s .15s, -webkit-transform .15s .15s;
    transition: width .15s, top .15s .15s, left .15s .15s, -webkit-transform .15s .15s;
    transition: width .15s, top .15s .15s, left .15s .15s, transform .15s .15s;
    transition: width .15s, top .15s .15s, left .15s .15s, transform .15s .15s, -webkit-transform .15s .15s;
}

.admin-header .mobile-menu.is-open .menu span:nth-of-type(2) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: -webkit-transform .15s .15s;
    transition: -webkit-transform .15s .15s;
    transition: transform .15s .15s;
    transition: transform .15s .15s, -webkit-transform .15s .15s;
}

.admin-header .mobile-menu.is-open .menu span:nth-of-type(3) {
    width: 50%;
    right: 7%;
    top: 47%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: width .15s, top .15s .15s, right .15s .15s, -webkit-transform .15s .15s;
    transition: width .15s, top .15s .15s, right .15s .15s, -webkit-transform .15s .15s;
    transition: width .15s, top .15s .15s, right .15s .15s, transform .15s .15s;
    transition: width .15s, top .15s .15s, right .15s .15s, transform .15s .15s, -webkit-transform .15s .15s;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav {
    left: 0;
    z-index: -10;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .navbar__mobile-menu__item {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .navbar__mobile-menu__item:nth-child(1) {
    -webkit-transition: -webkit-transform 0.15s ease 0.1s;
    transition: -webkit-transform 0.15s ease 0.1s;
    transition: transform 0.15s ease 0.1s;
    transition: transform 0.15s ease 0.1s, -webkit-transform 0.15s ease 0.1s;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .navbar__mobile-menu__item:nth-child(2) {
    -webkit-transition: -webkit-transform 0.15s ease 0.2s;
    transition: -webkit-transform 0.15s ease 0.2s;
    transition: transform 0.15s ease 0.2s;
    transition: transform 0.15s ease 0.2s, -webkit-transform 0.15s ease 0.2s;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .navbar__mobile-menu__item:nth-child(3) {
    -webkit-transition: -webkit-transform 0.15s ease 0.3s;
    transition: -webkit-transform 0.15s ease 0.3s;
    transition: transform 0.15s ease 0.3s;
    transition: transform 0.15s ease 0.3s, -webkit-transform 0.15s ease 0.3s;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .navbar__mobile-menu__item:nth-child(4) {
    -webkit-transition: -webkit-transform 0.15s ease 0.4s;
    transition: -webkit-transform 0.15s ease 0.4s;
    transition: transform 0.15s ease 0.4s;
    transition: transform 0.15s ease 0.4s, -webkit-transform 0.15s ease 0.4s;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .navbar__mobile-menu__item:nth-child(5) {
    -webkit-transition: -webkit-transform 0.15s ease 0.5s;
    transition: -webkit-transform 0.15s ease 0.5s;
    transition: transform 0.15s ease 0.5s;
    transition: transform 0.15s ease 0.5s, -webkit-transform 0.15s ease 0.5s;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .navbar__mobile-menu__item:nth-child(6) {
    -webkit-transition: -webkit-transform 0.15s ease 0.6s;
    transition: -webkit-transform 0.15s ease 0.6s;
    transition: transform 0.15s ease 0.6s;
    transition: transform 0.15s ease 0.6s, -webkit-transform 0.15s ease 0.6s;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .language {
    position: relative;
    margin-top: 2rem;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .language a {
    width: 100%;
    justify-content: center;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .language .arrow-icon {
    position: absolute;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    right: 1rem;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .language .language__list {
    width: 100%;
    max-width: 75%;
    height: 0;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .language .language__list .language__item a {
    align-items: center;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .language .language__list .language__item.is-selected span {
    color: #ffab72;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .language.is-open .arrow-icon {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .language.is-open .language__list {
    display: flex;
    height: 30px;
    visibility: visible;
    opacity: 1;
    padding-top: 1rem;
}

@media screen and (max-width: 550px) {
    .admin-header .mobile-menu {
        display: flex;
    }
}

@-webkit-keyframes dash {
    from {
        stroke-dashoffset: 1000;
    }

    to {
        stroke-dashoffset: 0;
    }
}

@keyframes dash {
    from {
        stroke-dashoffset: 1000;
    }

    to {
        stroke-dashoffset: 0;
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: translateX(-50%) translateY(-50%) rotate(0deg);
        transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }

    to {
        -webkit-transform: translateX(-50%) translateY(-50%) rotate(360deg);
        transform: translateX(-50%) translateY(-50%) rotate(360deg);
    }
}

@keyframes spin {
    from {
        -webkit-transform: translateX(-50%) translateY(-50%) rotate(0deg);
        transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }

    to {
        -webkit-transform: translateX(-50%) translateY(-50%) rotate(360deg);
        transform: translateX(-50%) translateY(-50%) rotate(360deg);
    }
}

.mobile-bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    will-change: transform;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    display: -webkit-box;
    display: none;
    height: 50px;
    box-shadow: 0 -2px 5px -2px #333;
    background-color: #142B43;
}

.mobile-bottom-nav__item {
    flex-grow: 1;
    text-align: center;
    font-size: 12px;
    display: -webkit-box;
    display: none;
    flex-direction: column;
    justify-content: center;
}

.mobile-bottom-nav__item .fa {
    font-size: 20px !important;
}

.mobile-bottom-nav__item--active {
    color: #ffab72 !important;
}

.mobile-bottom-nav__item-content {
    display: -webkit-box;
    display: none;
    flex-direction: column;
    color: #fff;
}

@media screen and (max-width: 550px) {
    .mobile-bottom-nav {
        display: flex;
    }

    .mobile-bottom-nav__item {
        display: flex;
    }

    .mobile-bottom-nav__item-content {
        display: flex;
    }

}
.textimonial table {
    margin-top: 25px;
    border-collapse: collapse;
    background-color: white;
    overflow: hidden;
    width: 100%;
}

.textimonial th,
.textimonial td {
    font-family: 'Motnserrat', sans-serif;
    text-align: left;
    font-size: 12px;
    padding: 10px !important;
}

.textimonial th {
    background-color: #7691ab;
    color: white;
}
table {
    border-collapse: collapse;
    background-color: white;
    overflow: hidden;
    width: 100%;
}

th,
td {
    font-family: 'Motnserrat', sans-serif;
    text-align: left;
    font-size: 12px;
    padding: 10px !important;
}

th {
    background-color: #7691ab;
    color: white;
}
/* quote */
.testimony .quote-image {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    padding-top: 60px;
    width: 80px;
}

/* text-style */
.testimony .testimonial-style .testimonial-caption-style {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 24px;
    color: #354052;
    line-height: 30px;
    bottom: 40%;
    text-shadow: none;
    top: 10%;
}

.testimony .testimonial-style .testimonial-authors {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 20px;
    color: #354052;
    line-height: 30px;
    margin-top: 40px;
}

/* carousel-height-control */
.testimony .testimonial-style .carousel-inner>.item {
    padding-top: auto;
    padding-bottom: auto;
    height: 380px;
}

/* carousel-left-right-control */
.testimony .testimonial-style .carousel-control.left {
    background: none;
    box-shadow: none;
    text-shadow: none;
}

.testimony .testimonial-style .carousel-control.right {
    background: none;
    box-shadow: none;
    text-shadow: none;
}

.testimony .testimonial-style .carousel-control {
    color: #354052;
}

.testimony .testimonial-style .carousel-control:hover {
    color: #354052;
    text-decoration: none;
    -webkit-transition: background-color 1s ease;
    transition: background-color 1s ease;
}

/* indicators */
.testimony .testimonial-style .carousel-indicators .active {
    background-color: #fff;
    width: 14px;
    height: 14px;
    -webkit-transition: background 1s cubic-bezier(0.22, 0.61, 0.36, 1);
    transition: background 1s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.testimony .testimonial-style .carousel-indicators li {
    border: 2px solid #354052;
    background-color: #354052;
}

@media only screen and (max-width: 600px) {
    .testimony .testimonial-style .testimonial-caption-style {
        font-size: 16px;
    }
}
.image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.full {
    -webkit-transition: opacity 400ms ease 0ms;
    transition: opacity 400ms ease 0ms;
}

.thumb {
    -webkit-filter: blur(20px);
            filter: blur(20px);
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-transition: visibility 0ms ease 400ms;
    transition: visibility 0ms ease 400ms;
}
.create-blog {
    margin-bottom: 10vh;
}
.editor .ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
}

/* Snow Theme */
.editor .ql-snow.ql-toolbar {
    display: block;
    background: #9ca9b1;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

/* Bubble Theme */
.editor .ql-bubble .ql-editor {
    border: 1px solid #ccc;
    border-radius: 0.5em;
}

.editor .ql-editor {
    min-height: 18em;
}
.jspin-nested-loading {
    position: relative;
}

.jspin {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: absolute;
    display: none;
    color: #216462;
    text-align: center;
    vertical-align: middle;
    opacity: 0;
    /* -webkit-transition: -webkit-transform .3s cubic-bezier(.78, .14, .15, .86); */
    /* transition: -webkit-transform .3s cubic-bezier(.78, .14, .15, .86); */
    /* transition: transform .3s cubic-bezier(.78, .14, .15, .86); */
    /* transition: transform .3s cubic-bezier(.78, .14, .15, .86), -webkit-transform .3s cubic-bezier(.78, .14, .15, .86); */
}

.jspin-spinning {
    position: static;
    display: inline-block;
    opacity: 1;
}

.jspin-nested-loading>.jspin {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    display: block;
    width: 100%;
    height: 100%;
    max-height: 400px;
}

.jspin-blur {
    clear: both;
    overflow: hidden;
    opacity: 0.3;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
}

.jspin-container {
    position: relative;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
}

.jspin-blur::after {
    opacity: .4;
    pointer-events: auto;
}

.jspin-container::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: none \9;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0;
    -webkit-transition: all .3s;
    transition: all .3s;
    content: '';
    pointer-events: none;
}

.jspin-nested-loading>.jspin .jspin-dot {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px;
}

