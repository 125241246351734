.dashboard {
    margin-bottom: 10vh;
}

.dashboard .order-card {
    color: #fff;
}

.dashboard .bg-c-blue {
    background: linear-gradient(45deg, #4099ff, #73b4ff);
}

.dashboard .bg-c-green {
    background: linear-gradient(45deg, #2ed8b6, #59e0c5);
}

.dashboard .bg-c-yellow {
    background: linear-gradient(45deg, #FFB64D, #ffcb80);
}

.dashboard .bg-c-pink {
    background: linear-gradient(45deg, #FF5370, #ff869a);
}


.dashboard .card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
    box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
    border: none;
    margin-bottom: 30px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.dashboard .card .card-block {
    padding: 25px;
}

.dashboard .order-card i {
    font-size: 26px;
}

.dashboard .f-left {
    float: left;
}

.dashboard .f-right {
    float: right;
}