@import url("https://fonts.googleapis.com/css?family=Josefin+Sans");

.admin-header {
    font-size: 12px;
    font-family: 'Josefin Sans', 'Roboto', sans-serif;
    background-color: #003e7c;
    color: rgba(255, 255, 255, 0.75);
    overflow: hidden;
}

.admin-header a {
    color: inherit;
    text-decoration: none;
    outline: none;
}

.admin-header li {
    width: 100%;
}

.admin-header svg {
    width: 100%;
    max-width: 30px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.admin-header .u-uppercase {
    margin-top: 10px;
    text-transform: uppercase;
    font-size: smaller;
}

.admin-header .fake-button::active {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
}

.admin-header .flex-column-nowrap {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-flow: column nowrap;
}

.admin-header .flex-row-wrap {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    margin-top: 20px;
}

.admin-header .flex-row-nowrap {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row nowrap;
}

.admin-header .sidebar__logo,
.navbar__logo {
    position: relative;
    margin-top: .5rem;
    padding-top: .4rem;
    z-index: 10;
}

.admin-header .sidebar__logo .logo,
.navbar__logo .logo {
    top: 50%;
    left: 50%;
    color: rgba(255, 255, 255, 0.75);
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: -4px;
    text-shadow: 0 0.2rem 0.4rem #2f2f32, 0 0.2rem 0.4rem #2f2f32;
}

.admin-header .sidebar__logo1::before,
.sidebar__logo1::after,
.admin-header .navbar__logo1::before,
.admin-header .navbar__logo1::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
    width: 2rem;
    padding: 1rem;
    border: 5px solid #ffab72;
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
    -webkit-animation: 10s infinite alternate ease-in-out spin;
    animation: 10s infinite alternate ease-in-out spin;
}

.admin-header .sidebar__logo::before,
.navbar__logo::before {
    border-color: #ffab72 #ffab72 transparent transparent;
    z-index: -1;
}

.admin-header .sidebar__logo::after,
.navbar__logo::after {
    border-color: transparent transparent #ffab72 #ffab72;
    z-index: 1;
    box-shadow: 4px 2px 1px #2f2f32;
}

@media screen and (max-width: 768px) {

    .sidebar__logo,
    .navbar__logo {
        margin: 0 0 0 1.5rem;
    }
}

@media screen and (max-width: 550px) {

    .sidebar__logo,
    .navbar__logo {
        margin: 0 0 0 2.5rem;
    }
}

.admin-header .desktop-menu {
    display: -webkit-box;
    display: flex;
    position: relative;
}

.admin-header .desktop-menu .sidebar {
    position: fixed;
    width: 100%;
    max-width: 75px;
    height: 100vh;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-content: center;
    text-align: center;
    z-index: 10;
    background-color: #142B43;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
}

@media screen and (max-width: 768px) {
    .desktop-menu .sidebar {
        width: 100%;
        max-width: 100vw;
        height: 100%;
        max-height: 75px;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-flow: row nowrap;
        -webkit-box-align: center;
        align-items: center;
    }
}

.admin-header .desktop-menu .sidebar__nav-list {
    padding: 0;
}

.admin-header .desktop-menu .sidebar__nav-list .nav-list__item {
    opacity: .5;
    cursor: pointer;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.admin-header .desktop-menu .sidebar__nav-list .nav-list__item a {
    position: relative;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-transition: -webkit-transform .15s ease;
    transition: -webkit-transform .15s ease;
    transition: transform .15s ease;
    transition: transform .15s ease, -webkit-transform .15s ease;
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__nav-list .nav-list__item a {
        height: 100%;
    }
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__nav-list .nav-list__item a svg {
        align-self: flex-end;
        margin-top: .5rem;
    }
}

.admin-header .desktop-menu .sidebar__nav-list .nav-list__item a span {
    display: block;
    flex-basis: 100%;
    padding-top: .25rem;
    -webkit-transform: translateX(-200px);
    transform: translateX(-200px);
    -webkit-transition: all .15s ease;
    transition: all .15s ease;
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__nav-list .nav-list__item a span {
        -webkit-transform: translateY(-200px);
        transform: translateY(-200px);
        align-self: flex-end;
        padding-bottom: .5rem;
    }
}

.admin-header .desktop-menu .sidebar__nav-list .nav-list__item:last-child {
    margin-bottom: 0;
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__nav-list .nav-list__item:last-child {
        margin-right: 0;
    }
}

.admin-header .desktop-menu .sidebar__nav-list .nav-list__item.active,
.admin-header .desktop-menu .sidebar__nav-list .nav-list__item:hover {
    opacity: 1;
}

.admin-header .desktop-menu .sidebar__nav-list .nav-list__item.active span,
.admin-header .desktop-menu .sidebar__nav-list .nav-list__item:hover span {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    color: #ffab72;
    font-weight: bold;
}

.admin-header .desktop-menu .sidebar__nav-list .nav-list__item.active svg,
.admin-header .desktop-menu .sidebar__nav-list .nav-list__item:hover svg {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    -webkit-animation: dash 12s ease alternate;
    animation: dash 12s ease alternate;
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__nav-list .nav-list__item {
        height: 100%;
        margin: 0;
    }
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__nav-list {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-flow: row nowrap;
        -webkit-box-align: center;
        align-items: center;
        width: 100%;
        max-width: 45%;
        height: 100%;
    }
}

@media screen and (max-width: 550px) {
    .admin-header .desktop-menu .sidebar__nav-list {
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .admin-header .desktop-menu {
        display: none;
    }
}

.admin-header .desktop-menu .sidebar__extra-content {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-flow: column nowrap;
    padding: 0;
}

.admin-header .desktop-menu .sidebar__extra-content span {
    display: block;
    width: 100%;
    text-align: center;
    color: #ffab72;
    font-weight: bold;
    -webkit-transition: all .15s ease;
    transition: all .15s ease;
    -webkit-transform: translateX(-200px);
    transform: translateX(-200px);
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__extra-content span {
        align-self: flex-end;
        padding-bottom: .5rem;
        -webkit-transform: translateY(-200px);
        transform: translateY(-200px);
    }
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language {
    display: -webkit-box;
    display: flex;
    position: relative;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    -webkit-box-pack: center;
    justify-content: center;
    margin-bottom: 1rem;
    cursor: pointer;
    -webkit-transition: all .15s ease;
    transition: all .15s ease;
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__extra-content .extra-content__language svg {
        align-self: flex-end;
        margin-top: .5rem;
    }
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector {
    position: absolute;
    top: 0;
    right: -200px;
    left: 75px;
    width: 130px;
    padding-left: 1.5rem;
    visibility: hidden;
    z-index: 1;
    -webkit-transition: visibility 0ms ease-out 600ms;
    transition: visibility 0ms ease-out 600ms;
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector .language-selector__item {
    margin-bottom: 0;
    padding: .5rem 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.75);
    background-color: #1b1d1d;
    opacity: 0;
    -webkit-transform: translateX(-200px);
    transform: translateX(-200px);
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector .language-selector__item a {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row nowrap;
    -webkit-box-pack: center;
    justify-content: center;
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector .language-selector__item span {
    width: 25px;
    height: 6px;
    align-self: center;
    color: rgba(255, 255, 255, 0.75);
    -webkit-transform: scale(1);
    transform: scale(1);
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector .language-selector__item svg {
        margin: 0;
    }
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector .language-selector__item:nth-of-type(1) {
    border-top: 1px solid rgba(255, 255, 255, 0.75);
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector .language-selector__item:nth-child(1) {
    -webkit-transition: opacity 100ms ease-out 0.3s, -webkit-transform 0.15s ease 0.3s;
    transition: opacity 100ms ease-out 0.3s, -webkit-transform 0.15s ease 0.3s;
    transition: transform 0.15s ease 0.3s, opacity 100ms ease-out 0.3s;
    transition: transform 0.15s ease 0.3s, opacity 100ms ease-out 0.3s, -webkit-transform 0.15s ease 0.3s;
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector .language-selector__item:nth-child(2) {
    -webkit-transition: opacity 100ms ease-out 0.2s, -webkit-transform 0.15s ease 0.2s;
    transition: opacity 100ms ease-out 0.2s, -webkit-transform 0.15s ease 0.2s;
    transition: transform 0.15s ease 0.2s, opacity 100ms ease-out 0.2s;
    transition: transform 0.15s ease 0.2s, opacity 100ms ease-out 0.2s, -webkit-transform 0.15s ease 0.2s;
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector .language-selector__item:nth-child(3) {
    -webkit-transition: opacity 100ms ease-out 0.1s, -webkit-transform 0.15s ease 0.1s;
    transition: opacity 100ms ease-out 0.1s, -webkit-transform 0.15s ease 0.1s;
    transition: transform 0.15s ease 0.1s, opacity 100ms ease-out 0.1s;
    transition: transform 0.15s ease 0.1s, opacity 100ms ease-out 0.1s, -webkit-transform 0.15s ease 0.1s;
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector .language-selector__item.is-selected {
    background-color: #585f5f;
    font-weight: bold;
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector .language-selector__item.is-selected a span {
    color: #ffab72;
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector .language-selector__item:hover {
    background-color: #585f5f;
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector .language-selector__item {
        -webkit-transform: translateX(200px);
        transform: translateX(200px);
    }
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__extra-content .extra-content__language .extra-content__language-selector {
        left: -17px;
        top: 83px;
    }
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language svg:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 2rem;
    width: 1.1rem;
    opacity: 0;
    -webkit-transform: translateY(-85%);
    transform: translateY(-85%);
    -webkit-transition: all .15s ease;
    transition: all .15s ease;
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__extra-content .extra-content__language svg:nth-of-type(2) {
        -webkit-transform: translateY(-130%) rotate(90deg);
        transform: translateY(-130%) rotate(90deg);
    }
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language:hover {
    -webkit-transform: translateX(-1rem);
    transform: translateX(-1rem);
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language:hover span {
    -webkit-transform: translateX(1rem);
    transform: translateX(1rem);
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language:hover svg:nth-of-type(2) {
    opacity: 1;
    right: -.5rem;
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language.is-open {
    -webkit-transform: translateX(-1rem);
    transform: translateX(-1rem);
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language.is-open .extra-content__language-selector {
    visibility: visible;
    -webkit-transition: visibility 0ms ease-out 0ms;
    transition: visibility 0ms ease-out 0ms;
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language.is-open .extra-content__language-selector li:nth-of-type(1) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    -webkit-transition: opacity 0.1s ease-out 0.1s, -webkit-transform 0.15s ease 0.1s;
    transition: opacity 0.1s ease-out 0.1s, -webkit-transform 0.15s ease 0.1s;
    transition: transform 0.15s ease 0.1s, opacity 0.1s ease-out 0.1s;
    transition: transform 0.15s ease 0.1s, opacity 0.1s ease-out 0.1s, -webkit-transform 0.15s ease 0.1s;
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language.is-open .extra-content__language-selector li:nth-of-type(2) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    -webkit-transition: opacity 0.1s ease-out 0.2s, -webkit-transform 0.15s ease 0.2s;
    transition: opacity 0.1s ease-out 0.2s, -webkit-transform 0.15s ease 0.2s;
    transition: transform 0.15s ease 0.2s, opacity 0.1s ease-out 0.2s;
    transition: transform 0.15s ease 0.2s, opacity 0.1s ease-out 0.2s, -webkit-transform 0.15s ease 0.2s;
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language.is-open .extra-content__language-selector li:nth-of-type(3) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    -webkit-transition: opacity 0.1s ease-out 0.3s, -webkit-transform 0.15s ease 0.3s;
    transition: opacity 0.1s ease-out 0.3s, -webkit-transform 0.15s ease 0.3s;
    transition: transform 0.15s ease 0.3s, opacity 0.1s ease-out 0.3s;
    transition: transform 0.15s ease 0.3s, opacity 0.1s ease-out 0.3s, -webkit-transform 0.15s ease 0.3s;
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language.is-open span {
    -webkit-transform: scale(0.95) translateX(1rem);
    transform: scale(0.95) translateX(1rem);
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language.is-open svg {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__language.is-open svg:nth-of-type(2) {
    right: -.5rem;
    opacity: 1;
    -webkit-transform: translateY(-85%) scale(0.9) rotate(180deg);
    transform: translateY(-85%) scale(0.9) rotate(180deg);
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__extra-content .extra-content__language.is-open svg:nth-of-type(2) {
        -webkit-transform: translateY(-130%) scale(0.9) rotate(-90deg);
        transform: translateY(-130%) scale(0.9) rotate(-90deg);
    }
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__extra-content .extra-content__language {
        margin-bottom: 0;
    }
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__share {
    -webkit-box-pack: center;
    justify-content: center;
    margin-bottom: .5rem;
    cursor: pointer;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__extra-content .extra-content__share svg {
        align-self: flex-end;
        margin-top: .5rem;
    }
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__extra-content .extra-content__share span {
        align-self: flex-end;
        padding-bottom: .5rem;
    }
}

.admin-header .desktop-menu .sidebar__extra-content .extra-content__share:hover span {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__extra-content .extra-content__share {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 768px) {
    .admin-header .desktop-menu .sidebar__extra-content {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-flow: row nowrap;
        height: 100%;
    }
}

@media screen and (max-width: 550px) {
    .admin-header .desktop-menu .sidebar__extra-content {
        display: none;
    }
}

.admin-header .mobile-menu {
    display: none;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
    background-color: #1b1d1d;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.admin-header .mobile-menu .navbar__mobile-menu {
    display: none;
    position: relative;
    height: 100%;
}

.admin-header .mobile-menu .navbar__mobile-menu .menu {
    position: relative;
    width: 50px;
    height: 40px;
    margin: 1.55rem 1.55rem 0 1.55rem;
    text-align: left;
    cursor: pointer;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
}

.admin-header .mobile-menu .navbar__mobile-menu .menu span {
    display: inline-block;
    position: absolute;
    height: 3px;
    width: 50px;
    background: rgba(255, 255, 255, 0.75);
    border-radius: 2px;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
}

.admin-header .mobile-menu .navbar__mobile-menu .menu span:nth-of-type(1) {
    -webkit-transition: width .15s .15s, top .15s, right .15s, -webkit-transform .15s;
    transition: width .15s .15s, top .15s, right .15s, -webkit-transform .15s;
    transition: width .15s .15s, top .15s, right .15s, transform .15s;
    transition: width .15s .15s, top .15s, right .15s, transform .15s, -webkit-transform .15s;
}

.admin-header .mobile-menu .navbar__mobile-menu .menu span:nth-of-type(2) {
    top: 10px;
    -webkit-transition: -webkit-transform .15s;
    transition: -webkit-transform .15s;
    transition: transform .15s;
    transition: transform .15s, -webkit-transform .15s;
}

.admin-header .mobile-menu .navbar__mobile-menu .menu span:nth-of-type(3) {
    top: 20px;
    right: 0;
    -webkit-transition: width .15s .15s, top .15s, right .15s, -webkit-transform .15s;
    transition: width .15s .15s, top .15s, right .15s, -webkit-transform .15s;
    transition: width .15s .15s, top .15s, right .15s, transform .15s;
    transition: width .15s .15s, top .15s, right .15s, transform .15s, -webkit-transform .15s;
}

@media screen and (max-width: 550px) {
    .admin-header .mobile-menu .navbar__mobile-menu {
        display: block;
    }
}

.admin-header .mobile-menu .navbar__mobile-menu__nav {
    display: block;
    position: absolute;
    left: 100%;
    top: 64px;
    height: calc(100vh - 65px);
    min-height: calc(100vh - 65px);
    width: 100vw;
    background: #1b1d1d;
    overflow-x: hidden;
    -webkit-transition: all 0.3s ease 0.5s;
    transition: all 0.3s ease 0.5s;
}

.admin-header .mobile-menu .navbar__mobile-menu__nav .navbar__mobile-menu__list {
    margin-top: 1rem;
    font-size: 1.5rem;
    -webkit-box-pack: center;
    justify-content: center;
}

.admin-header .mobile-menu .navbar__mobile-menu__nav .navbar__mobile-menu__list .navbar__mobile-menu__item {
    padding: 1rem 0;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    width: 75%;
    margin: 0 auto;
    border-bottom: 1px solid rgba(255, 255, 255, 0.75);
    -webkit-transform: translateX(550px);
    transform: translateX(550px);
}

.admin-header .mobile-menu .navbar__mobile-menu__nav .navbar__mobile-menu__list .navbar__mobile-menu__item:nth-child(1) {
    -webkit-transition: -webkit-transform 0.15s ease 0.3s;
    transition: -webkit-transform 0.15s ease 0.3s;
    transition: transform 0.15s ease 0.3s;
    transition: transform 0.15s ease 0.3s, -webkit-transform 0.15s ease 0.3s;
}

.admin-header .mobile-menu .navbar__mobile-menu__nav .navbar__mobile-menu__list .navbar__mobile-menu__item:nth-child(2) {
    -webkit-transition: -webkit-transform 0.15s ease 0.2s;
    transition: -webkit-transform 0.15s ease 0.2s;
    transition: transform 0.15s ease 0.2s;
    transition: transform 0.15s ease 0.2s, -webkit-transform 0.15s ease 0.2s;
}

.admin-header .mobile-menu .navbar__mobile-menu__nav .navbar__mobile-menu__list .navbar__mobile-menu__item:nth-child(3) {
    -webkit-transition: -webkit-transform 0.15s ease 0.1s;
    transition: -webkit-transform 0.15s ease 0.1s;
    transition: transform 0.15s ease 0.1s;
    transition: transform 0.15s ease 0.1s, -webkit-transform 0.15s ease 0.1s;
}

.admin-header .mobile-menu .navbar__mobile-menu__nav .navbar__mobile-menu__list .navbar__mobile-menu__item:nth-child(4) {
    -webkit-transition: -webkit-transform 0.15s ease 0s;
    transition: -webkit-transform 0.15s ease 0s;
    transition: transform 0.15s ease 0s;
    transition: transform 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
}

.admin-header .mobile-menu .navbar__mobile-menu__nav .navbar__mobile-menu__list .navbar__mobile-menu__item:nth-child(5) {
    -webkit-transition: -webkit-transform 0.15s ease -0.1s;
    transition: -webkit-transform 0.15s ease -0.1s;
    transition: transform 0.15s ease -0.1s;
    transition: transform 0.15s ease -0.1s, -webkit-transform 0.15s ease -0.1s;
}

.admin-header .mobile-menu .navbar__mobile-menu__nav .navbar__mobile-menu__list .navbar__mobile-menu__item:nth-child(6) {
    -webkit-transition: -webkit-transform 0.15s ease -0.2s;
    transition: -webkit-transform 0.15s ease -0.2s;
    transition: transform 0.15s ease -0.2s;
    transition: transform 0.15s ease -0.2s, -webkit-transform 0.15s ease -0.2s;
}

.admin-header .mobile-menu .navbar__mobile-menu__nav .navbar__mobile-menu__list .navbar__mobile-menu__item.active {
    color: #ffab72;
    border-bottom-color: #ffab72;
}

.admin-header .mobile-menu .navbar__mobile-menu__nav .navbar__mobile-menu__list .navbar__mobile-menu__item:active a {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
}

.admin-header .mobile-menu.is-open {
    box-shadow: unset;
}

.admin-header .mobile-menu.is-open .menu span:nth-of-type(1) {
    top: 2%;
    left: 7%;
    width: 50%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: width .15s, top .15s .15s, left .15s .15s, -webkit-transform .15s .15s;
    transition: width .15s, top .15s .15s, left .15s .15s, -webkit-transform .15s .15s;
    transition: width .15s, top .15s .15s, left .15s .15s, transform .15s .15s;
    transition: width .15s, top .15s .15s, left .15s .15s, transform .15s .15s, -webkit-transform .15s .15s;
}

.admin-header .mobile-menu.is-open .menu span:nth-of-type(2) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: -webkit-transform .15s .15s;
    transition: -webkit-transform .15s .15s;
    transition: transform .15s .15s;
    transition: transform .15s .15s, -webkit-transform .15s .15s;
}

.admin-header .mobile-menu.is-open .menu span:nth-of-type(3) {
    width: 50%;
    right: 7%;
    top: 47%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: width .15s, top .15s .15s, right .15s .15s, -webkit-transform .15s .15s;
    transition: width .15s, top .15s .15s, right .15s .15s, -webkit-transform .15s .15s;
    transition: width .15s, top .15s .15s, right .15s .15s, transform .15s .15s;
    transition: width .15s, top .15s .15s, right .15s .15s, transform .15s .15s, -webkit-transform .15s .15s;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav {
    left: 0;
    z-index: -10;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .navbar__mobile-menu__item {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .navbar__mobile-menu__item:nth-child(1) {
    -webkit-transition: -webkit-transform 0.15s ease 0.1s;
    transition: -webkit-transform 0.15s ease 0.1s;
    transition: transform 0.15s ease 0.1s;
    transition: transform 0.15s ease 0.1s, -webkit-transform 0.15s ease 0.1s;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .navbar__mobile-menu__item:nth-child(2) {
    -webkit-transition: -webkit-transform 0.15s ease 0.2s;
    transition: -webkit-transform 0.15s ease 0.2s;
    transition: transform 0.15s ease 0.2s;
    transition: transform 0.15s ease 0.2s, -webkit-transform 0.15s ease 0.2s;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .navbar__mobile-menu__item:nth-child(3) {
    -webkit-transition: -webkit-transform 0.15s ease 0.3s;
    transition: -webkit-transform 0.15s ease 0.3s;
    transition: transform 0.15s ease 0.3s;
    transition: transform 0.15s ease 0.3s, -webkit-transform 0.15s ease 0.3s;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .navbar__mobile-menu__item:nth-child(4) {
    -webkit-transition: -webkit-transform 0.15s ease 0.4s;
    transition: -webkit-transform 0.15s ease 0.4s;
    transition: transform 0.15s ease 0.4s;
    transition: transform 0.15s ease 0.4s, -webkit-transform 0.15s ease 0.4s;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .navbar__mobile-menu__item:nth-child(5) {
    -webkit-transition: -webkit-transform 0.15s ease 0.5s;
    transition: -webkit-transform 0.15s ease 0.5s;
    transition: transform 0.15s ease 0.5s;
    transition: transform 0.15s ease 0.5s, -webkit-transform 0.15s ease 0.5s;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .navbar__mobile-menu__item:nth-child(6) {
    -webkit-transition: -webkit-transform 0.15s ease 0.6s;
    transition: -webkit-transform 0.15s ease 0.6s;
    transition: transform 0.15s ease 0.6s;
    transition: transform 0.15s ease 0.6s, -webkit-transform 0.15s ease 0.6s;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .language {
    position: relative;
    margin-top: 2rem;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .language a {
    width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .language .arrow-icon {
    position: absolute;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    right: 1rem;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .language .language__list {
    width: 100%;
    max-width: 75%;
    height: 0;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 1rem;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .language .language__list .language__item a {
    -webkit-box-align: center;
    align-items: center;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .language .language__list .language__item.is-selected span {
    color: #ffab72;
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .language.is-open .arrow-icon {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.admin-header .mobile-menu.is-open .navbar__mobile-menu__nav .language.is-open .language__list {
    display: -webkit-box;
    display: flex;
    height: 30px;
    visibility: visible;
    opacity: 1;
    padding-top: 1rem;
}

@media screen and (max-width: 550px) {
    .admin-header .mobile-menu {
        display: -webkit-box;
        display: flex;
    }
}

@-webkit-keyframes dash {
    from {
        stroke-dashoffset: 1000;
    }

    to {
        stroke-dashoffset: 0;
    }
}

@keyframes dash {
    from {
        stroke-dashoffset: 1000;
    }

    to {
        stroke-dashoffset: 0;
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: translateX(-50%) translateY(-50%) rotate(0deg);
        transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }

    to {
        -webkit-transform: translateX(-50%) translateY(-50%) rotate(360deg);
        transform: translateX(-50%) translateY(-50%) rotate(360deg);
    }
}

@keyframes spin {
    from {
        -webkit-transform: translateX(-50%) translateY(-50%) rotate(0deg);
        transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }

    to {
        -webkit-transform: translateX(-50%) translateY(-50%) rotate(360deg);
        transform: translateX(-50%) translateY(-50%) rotate(360deg);
    }
}

.mobile-bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    will-change: transform;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    display: -webkit-box;
    display: none;
    height: 50px;
    box-shadow: 0 -2px 5px -2px #333;
    background-color: #142B43;
}

.mobile-bottom-nav__item {
    -webkit-box-flex: 1;
    flex-grow: 1;
    text-align: center;
    font-size: 12px;
    display: -webkit-box;
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
}

.mobile-bottom-nav__item .fa {
    font-size: 20px !important;
}

.mobile-bottom-nav__item--active {
    color: #ffab72 !important;
}

.mobile-bottom-nav__item-content {
    display: -webkit-box;
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    color: #fff;
}

@media screen and (max-width: 550px) {
    .mobile-bottom-nav {
        display: flex;
    }

    .mobile-bottom-nav__item {
        display: flex;
    }

    .mobile-bottom-nav__item-content {
        display: flex;
    }

}