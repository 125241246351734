/* quote */
.testimony .quote-image {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    padding-top: 60px;
    width: 80px;
}

/* text-style */
.testimony .testimonial-style .testimonial-caption-style {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 24px;
    color: #354052;
    line-height: 30px;
    bottom: 40%;
    text-shadow: none;
    top: 10%;
}

.testimony .testimonial-style .testimonial-authors {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 20px;
    color: #354052;
    line-height: 30px;
    margin-top: 40px;
}

/* carousel-height-control */
.testimony .testimonial-style .carousel-inner>.item {
    padding-top: auto;
    padding-bottom: auto;
    height: 380px;
}

/* carousel-left-right-control */
.testimony .testimonial-style .carousel-control.left {
    background: none;
    box-shadow: none;
    text-shadow: none;
}

.testimony .testimonial-style .carousel-control.right {
    background: none;
    box-shadow: none;
    text-shadow: none;
}

.testimony .testimonial-style .carousel-control {
    color: #354052;
}

.testimony .testimonial-style .carousel-control:hover {
    color: #354052;
    text-decoration: none;
    transition: background-color 1s ease;
}

/* indicators */
.testimony .testimonial-style .carousel-indicators .active {
    background-color: #fff;
    width: 14px;
    height: 14px;
    transition: background 1s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.testimony .testimonial-style .carousel-indicators li {
    border: 2px solid #354052;
    background-color: #354052;
}

@media only screen and (max-width: 600px) {
    .testimony .testimonial-style .testimonial-caption-style {
        font-size: 16px;
    }
}